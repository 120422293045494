import PatchCausesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patch-causes/PatchCausesTemplate';

import { PATCH_CAUSE, FIRST_PATCH_OCCURRENCE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/PatchCauses/PatchCausesTemplate'
};

const createStory = props => () => ({
  components: { PatchCausesTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <patch-causes-template v-bind="$options.storyProps" />
  </div>`
});

export const TeenagerAge = createStory({
  age: 13,
  firstPatchOccurrences: FIRST_PATCH_OCCURRENCE.TEENAGER,
  patchCauses: [PATCH_CAUSE.FOODS, PATCH_CAUSE.ITCHY_MATERIALS]
});

export const YoungAdultAge = createStory({
  age: 19,
  firstPatchOccurrences: FIRST_PATCH_OCCURRENCE.TEENAGER,
  patchCauses: [PATCH_CAUSE.FOODS, PATCH_CAUSE.ITCHY_MATERIALS]
});

export const MutureAge = createStory({
  age: 42,
  firstPatchOccurrences: FIRST_PATCH_OCCURRENCE.YOUNG_ADULT,
  patchCauses: [PATCH_CAUSE.FOODS, PATCH_CAUSE.ITCHY_MATERIALS, PATCH_CAUSE.INJURY]
});
